.signature {
  display: flex;
  justify-content: space-between;
  margin-left: 10vw;
  margin-right: 10vw;
  margin-bottom: 1.5vw;
}


.signature .description {
  border: 0px solid green;
  font-size: 1vw;
  font-weight: bold;
  margin-bottom: 1vw;
  height: fit-content;
}

.signature .details .initials {
  text-transform: uppercase;
  font-weight: bold;
}

@media screen and (max-width: 600px){
  .signature {
      margin-top: 4vw;
  }
  .signature .description {
    font-size: 2vw;
  }
}