.applications {
  height: 70vw;
}

.applications .background-banner {
  height: 30vw;
  background-image: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(../../assets/images/home-page/banner1.jpeg);
  background-position: center;
  background-position: 100px 50px;
}

.applications .main-section {
  top: -7vw;
  margin: 50px;
  border: 0px solid red;
  position: relative;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  background: white;
  border-radius: 6px;
  padding-bottom: 5vw;
}

.applications .main-section .logo-section {
  border: 0px solid red;
  width: 100%;
  height: 100px ;
  display: flex;
  justify-content: center;
}

.applications .main-section .logo-section .logo {
  border: 0px solid green;
  height: 11vw;
  width: 10vw;
  background-image: url(../../assets/images/logo.png);
  background-position: center;
  background-size: cover;
  top: -5vw;
  display: flex;
  position: absolute;
}

.applications .main-section .heading {
  border: 0px solid green;
  text-align: center;
  font-family: "Roboto Slab", "Times New Roman", serif;
  font-weight: 700;
  font-size: x-large;
  height: 5vw;
}

.applications .main-section .cards-board {
  border: 0px solid green;
  width: 100%;
  height: 20vw;
  display: flex;
  justify-content: center;
}

.applications .main-section .cards-board .cards-container {
  border: 0px solid red;
  width: 50%;
  overflow: scroll;
  padding-top: 3vw;
}

.applications .main-section .cards-board .cards-container .card {
  border: 0px solid black;
  width: 99%;
  height: 8vw;
  display: flex;
  justify-content: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 6px;
  margin-bottom: 5vw;
  margin-left: 1px;
  margin-right: 1px;
}

.applications .main-section .cards-container .card .header-bottom-container {
  width: 85%;
  border: 0px solid yellow;
}

.applications .main-section .cards-container .card .header-bottom-container .header-section {
  border: 0px solid red;
  top: -2vw;
  position: relative;
  border-radius: 4px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  display: flex;
  background: white;
}

.applications .main-section .cards-container .card .header-bottom-container .header-section .avatar {
  border: 0px solid red;
  width: 5vw;
  height: 5vw;
  top: -1vw;
  position: relative;
  margin-left: 1vw;
  border-radius: 4px;
  background: white;
  background-position: center;
  background-size: cover;
}

.applications .main-section .cards-container .card .header-bottom-container .header-section .subject-name {
  width: 100%;
  border: 0px solid;
  position: absolute;
  font-weight: 700;
  font-size: x-large;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1vw;
}

.applications .main-section .cards-container .card .header-bottom-container .bottom-section {
  border: 0px solid red;
  height: 30%;
  display: flex;
  justify-content: space-between;
  top: -1vw;
  position: relative;
}

.applications .main-section .cards-container .card .header-bottom-container .bottom-section .aplication-status {
  border: 0px solid black;
  height: 100%;
}

.applications .main-section .cards-container .card .header-bottom-container .bottom-section .applicant-name {
  border: 0px solid black;
  height: 100%;
}

.applications .main-section .cards-container .card .header-bottom-container .bottom-section .application-time-stamp {
  border: 0px solid black;
  height: 100%;
}

.no-applications {
   font-family: 'Raleway',sans-serif; 
   font-size: 40px; 
   font-weight: 400; 
   line-height: 72px; 
   margin: 0 0 24px; 
   text-align: center; 
   text-transform: uppercase;
   border: 0px solid red;
}

@media screen and (max-width: 600px){
  .applications {
    height: 160vw;
  }
  .applications .background-banner {
      background-position: center -50px;
      background-size: cover;
  }

  .applications .main-section {
    margin: 10px;
    height: 140vw;
    z-index: 1;
  }

  .applications .main-section .cards-board .cards-container {
    width: 100%;
    height: 100vw;
  }

  .applications .main-section .cards-board .cards-container .card {
    height: 15vw;
    margin-bottom: 10vw;
  }

  .applications .main-section .cards-container .card .header-bottom-container {
    width: 90%;
  }

  .applications .main-section .cards-container .card .header-bottom-container .header-section {
    height: 10vw;
  }

  .applications .main-section .cards-container .card .header-bottom-container .header-section .avatar {
    width: 10vw;
    height: 10vw;
  }

  .applications .main-section .logo-section {
    height: 50px;
  }

  .applications .main-section .cards-board {
    margin-top: 10vw;
  }
}