.application-status {
  border: 0px solid red;
  display: flex;
  justify-content: space-between;
  margin-left: 10vw;
  margin-right: 10vw;
  margin-bottom: 3vw;
  margin-top: 3vw;
}


.application-status .title {
  border: 0px solid green;
  font-size: 1vw;
  font-weight: bold;
  margin-bottom: 1vw;
  height: fit-content;
}

.signature .details .initials {
  text-transform: uppercase;
  font-weight: bold;
}

@media screen and (max-width: 600px){
  .application-status .title {
    font-size: 3vw;
    margin-top: auto;
  }
}