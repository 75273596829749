.footer {
    border: 0px solid red;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.footer .top {
    border: 0px solid green;
    height: 24vw;
    width: 100%;
    display: flex;
    align-self: center;
    margin-top: 30px;
    border-top: 3px solid;
    padding-top: 3vw;
}

.footer .top .left {
    border: 0px solid red;
    height: 20vw;
    display: flex;
    width: 40%;
    flex-direction: column;
}

.footer .top .left .contact-details {
    align-self: center;
    margin: 1vw;
}

.footer .top .left .contact-details .text {
    line-height: 1.7;
    font-size: 1.2vw;
}

.footer .top .left .logo {
    background-size: contain;
    align-self: center;
    height: 5.5vw;
    border: 0px solid black;
    width: 90%;
    display: flex;
    background-repeat: no-repeat;
    background-image: url('../../../assets/images/focus-mentorship.png');
}

.footer .top .right {
    border: 0px solid red;
    height: 20vw;
    width: 100%;
    display: flex;
}

.footer .top .right .nav-section {
    margin: 1vw;
    width: 100%;
}

.footer .top .right .nav-section .heading {
    font-size: 1.2vw;
    font-weight: 700;
    border-bottom: 2px solid;
    padding-bottom: 10px;
    margin-bottom: 2vw;
}

.footer .top .right .nav-section .nav-item {
    margin-bottom: 1vw;
    font-size: 1.3vw;
}

.footer .top .right .nav-section .nav-item:hover {
    color: #f7941e;
}

.footer .bottom {
    border: 0px solid red;
    width: 100%;
    align-self: center;
    background-color: #f7941e;
    display: flex;
    justify-content: space-between;
    padding-top: 1vw;
    padding-bottom: 1vw;
}

.footer .bottom .left {
    border: 0px solid black;
    margin-left: 5vw;
    color: white;
    font-size: 1.2vw;
    align-self: center;
}

.footer .bottom .right {
    border: 0px solid black;
    margin-right: 5vw;
}

.footer .bottom .right .facebook-logo {
    width: 0.75vw;
    height: 1.5vw;
}

.footer .bottom .right .instagram-logo {
    width: 1.5vw;
    height: 1.5vw;
    margin-left: 1vw;
}

.footer .bottom .right .whatsapp-logo {
    width: auto;
    height: 1.5vw;
    margin-left: 1vw;
}

@media screen and (max-width: 600px){
    .footer .top {
        height: fit-content;
        flex-direction: column;
    }

    .footer .top .left {
        display: flex;
        height: fit-content;
        display: flex;
        width: 100%;
    }

    .footer .top .left .logo {
        align-self: center;
        height: 20vw;
        width: 80%;
    }

    .footer .top .left .contact-details {
        margin: 4vw;
    }

    .footer .top .left .contact-details .text {
        line-height: 2.3;
        font-size: 3.5vw;
    }

    .footer .top .right {
        height: fit-content;
        flex-direction: column;
    }

    .footer .top .right .nav-section {
        margin: 8vw;
        width: auto;
    }

    .footer .top .right .nav-section .heading {
        font-size: 4.8vw;
        font-weight: 700;
        border-bottom: 4px solid;
        margin-bottom: 4vw;
    }

    .footer .top .right .nav-section .nav-item {
        margin-bottom: 4vw;
        font-size: 5.2vw;
    }

    .footer .bottom {
        padding-top: 4vw;
        padding-bottom: 4vw;
        flex-direction: column;
    }

    .footer .bottom .left {
        margin-left: unset;
        font-size: 5vw;
        text-align: center;
    }

    .footer .bottom .right {
        text-align: center;
    }

    .footer .bottom .right .facebook-logo {
        width: 5vw;
        height: 10vw;
        margin: 10vw;
    }

    .footer .bottom .right .instagram-logo {
        width: 10vw;
        height: 10vw;
        margin-left: unset;
        margin: 10vw;
    }

    .footer .bottom .right .whatsapp-logo {
        width: 10vw;
        height: 10vw;
        margin-left: unset;
        margin: 10vw;
    }
}