.main-banner {
    height: 74vw;
    background-image: url(../../../assets/images/home-page/banner1.jpeg);
    background-position: center;
    background-size: contain;
}

@media screen and (max-width: 1260px){
    .main-banner {
        margin-top: 0px;
    }
}

.banner-overlay-content {
    border: 0px solid red;
    margin-top: 60vw;
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: center;
}

.banner-overlay-content .first-text {
    z-index: 8;
    height: auto;
    color: rgb(255, 255, 255);
    text-decoration: none;
    white-space: normal;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    text-align: left;
    text-shadow: rgb(194 15 47) 0px 0px 0px;
    letter-spacing: 0px;
    font-weight: 400;
    font-size: 2vw;
    transform-origin: 50% 50%;
    opacity: 1;
    transform: translate(0px, 0px);
    overflow: visible;
    margin: 2vw;
}

.banner-overlay-content .second-text {
    z-index: 9;
    font-family: Roboto;
    color: rgb(255, 255, 255);
    text-decoration: none;
    white-space: normal;
    min-height: 0px;
    min-width: 0px;
    max-height: none;
    max-width: none;
    text-align: left;
    line-height: 22px;
    text-shadow: rgb(0 0 0 / 70%) 2px 2px 0px;
    letter-spacing: 0px;
    font-weight: 700;
    font-size: 3vw;
    transform-origin: 50% 50%;
    opacity: 1;
    transform: translate(0px, 0px);
    overflow: visible;
    margin: 2vw;
}

.banner-overlay-content .digital-stores-btns {
    border: 0px solid red;
    width: 50vw;
    height: 4vw;
    display: flex;
    justify-content: space-around;
}

.banner-overlay-content .digital-stores-btns .play-store {
    background-image: url(../../../assets/images/home-page/icons/play-store-logo.png);
    border: 0px solid red;
    width: 13vw;
    height: 4vw;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
}

.banner-overlay-content .digital-stores-btns .app-store {
    background-image: url(../../../assets/images/home-page/icons/app-store-logo.jpg);
    border: 0px solid red;
    width: 13vw;
    height: 4vw;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
}

.banner-overlay-content .digital-stores-btns .app-gallery {
    background-image: url(../../../assets/images/home-page/icons/app-gallery-logo.png);
    border: 0px solid red;
    width: 13vw;
    height: 4vw;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
}


/* Mobile */
@media screen and (max-width: 600px){
    .banner-overlay-content .first-text {
        font-size: 4vw;
    }
    .banner-overlay-content .second-text {
        font-size: 6vw;
    }
    .banner-overlay-content .digital-stores-btns {

    }
}