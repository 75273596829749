.side-nav-bar {
    /* border: 0px solid red; */
    width: 350px;
    height: 177.5%;
    position: absolute;
    display: none;
}

.side-nav-bar-open {
    animation: slideOutLeft 0.4s;
    background-color: black;
    position: fixed;
    display: block;
    right:  0px;
}

.side-nav-bar-close {
    display: flex;
    background-color: black;
    position: fixed;    
    display: block;
    right: -350px;
    z-index: 3;
}

.animation-slide-out-right {
    animation: slideOutRight 0.4s;
}

@keyframes slideOutLeft {
    from { right: -350px; }
    to   { right:  0px; }
}

@keyframes slideOutRight {
    from { right: 0px; }
    to   { right: -350px; }
}

.side-nav-bar-items {
    width: 100%;
    height: 100%;
    padding-top: 10px;
    padding-left: 25px;
    padding-right: 25px;
    color: white;
}

.side-nav-bar-item {
    /* border: 0px solid red; */
    border-bottom: 2px solid #979797;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: #979797;
    padding: 17px 0px;
    width: 80%;
}

.side-nav-bar-item:hover  {
    color: white;
    cursor: pointer;
}


.close-bar {
    border: 0px solid red;
    padding: 11%;    
}