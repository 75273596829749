.stats-section {
    border: 0px solid red;
    height: fit-content;
    width: 100%;
    display: flex;
    background-color: black;
}

.stats-section .record {
    border: 0px solid red;
    height: fit-content;
    width:100%;
    margin: 2vw;
    display: flex;
    color: white;
    flex-direction: column;
    padding-bottom: 5vw;
}

.stats-section .record .value {
    font-size: 5.3vw;
    align-self: center;
    margin-bottom: 3vw;
}

.stats-section .record .description {
    line-height: 1.7;
    font-size: 1.35vw;
    font-weight: 400;
    align-self: center;
    color: #d4e1fa;
    text-align: center;
}

.stats-section .record .title {
    font-size: 1.3vw;
    align-self: center;
    margin: 2vw;
    font-weight: 600;
}

.stats-section .record .title:hover {
    color: #f7941e;
}

/* Mobile */
@media screen and (max-width: 600px){
    .stats-section {
        display: block;
    }

    .stats-section .record {
        width: fit-content;
    }

    .stats-section .record .value {
        font-size: 21.2vw;
    }

    .stats-section .record .description {
        font-size: 5vw;
    }

    .stats-section .record .title {
        font-size: 5.2vw;
    }
}