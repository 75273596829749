.application-modal {
    display: block;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    padding-top: 3vw;
    padding-bottom: 3vw;
    background-color: rgba(0, 0, 0, 0.4);
    overflow: auto;
    transition: all 0.3s linear;
  }

.application-modal .application-modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border-radius: 4px;
    max-width: 60%;
    height: 40vw;
    padding-bottom: 10vw;
    overflow: auto;
  }

  .application-modal .application-modal-content .close {
    border: 0px solid red;
    width: 100%;
    height: 1vw;
    display: flex;
    justify-content: end;
  }

  .hide {
      display: none;
  }

  .application-modal .application-modal-content .applicant-name {
    border: 0px solid red;
    text-align: center;
    font-size: larger;
    font-weight: 900;
    line-height: 20px;
  }

  .application-modal .application-modal-content .grade {
    border: 0px solid red;
    text-align: center;
    font-size: 11px;
    line-height: 20px;
  }

  .application-modal .application-modal-content .section-header {
    border: 0px solid red;
    text-align: center;
    font-size: 2vw;
    font-weight: 800;
    margin-top: 2vw;
    margin-bottom: 1vw;
  }

  .application-modal .application-modal-content .key-value-pair {
    border: 0px solid red;
    height: 2vw;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .application-modal .application-modal-content .key-value-pair .key {
    border: 0px solid green;
    font-size: 1vw;
    font-weight: bold;
    margin-bottom: 1vw;
    height: fit-content;
    margin-left: 10vw;
  }

  .application-modal .application-modal-content .key-value-pair .value {
    border: 0px solid green;
    font-size: 1vw;
    margin-bottom: 1vw;
    margin-right: 10vw;
    height: fit-content;
  }

  .application-modal .application-modal-content .key-value-pair .value-bold {
    border: 0px solid green;
    font-size: 1vw;
    margin-bottom: 1vw;
    margin-right: 10vw;
    height: fit-content;
    font-weight: bold;
  }

  .application-modal .application-modal-content .key-value-pair .link {
    color: #f7941e;
    border: 0px solid green;
    font-size: 1vw;
    margin-bottom: 1vw;
    margin-right: 10vw;
    height: fit-content;
    cursor: pointer;
  }

  .application-modal .application-modal-content .paragraph {
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 1.5vw;
    margin-bottom: 1.5vw;
    font-style: italic;
    font-size: 1.2vw;
  }

@media screen and (max-width: 600px){
  .application-modal .application-modal-content {
    max-width: 90%;
    height: 150vw;
  }

  .application-modal {
    padding-top: 20vw;
    display: flex;
  }

  .application-modal .application-modal-content .section-header {
      font-size: large;
      margin-top: 5vw;
  }

  .application-modal .application-modal-content .key-value-pair .key {
    font-size: 2vw;
  }

  .application-modal .application-modal-content .key-value-pair {
    margin-bottom: 1vw;
  }
}