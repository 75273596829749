
.after-banner-section {
    border: 0px solid red;
    height: fit-content;
    width: 96%;
    margin: 2vw;
    margin-top: -20px;
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.after-banner-section .top-options {
    border: 0px solid red;
    height: 9vw;
    width: 100%;
    display: flex;
}

.after-banner-section .top-options .option {
    border: 0px solid green;
    height: 9vw;
    width: 25%;
    display: flex;
    justify-content: center;
    border-top: 10px solid white;
    border-bottom: 20px solid white;
}

.after-banner-section .top-options .option:hover {
    border-top: 10px solid #f7941e;
}

.after-banner-section .top-options .option .image {
    width: 6vw;
    height: 6vw;
    display: flex;
    align-self: center;
}

.after-banner-section .top-options .option .side-text {
    align-self: center;
    display: block;
    border: 0px solid red;
    padding-bottom: 10px;
    margin: 1vw;
}

.after-banner-section .top-options .option .side-text .title {
    font-size: 2vw;
    font-weight: bold;
    margin: 0.5vw;
}

.after-banner-section .top-options .option .side-text .sub-title {
    font-size: 1.4vw;
    color: #b7b7b7;
    margin: 0.5vw;
}


.after-banner-section .bottom-section  {
    margin-top: 6vw;
    border: 0px solid red;
    width: 100%;
    display: flex;
}

.after-banner-section .bottom-section .left-section {
    border: 0px solid red;
    height: 70vw;
    width: 100%;
    background-image: url(../../../assets/images/poster3.jpg);
    background-position: center;
    background-size: cover;
}

.after-banner-section .bottom-section .right-section {
    border: 0px solid red;
    height: 70vw;
    width: 100%;
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.7)), url(../../../assets/images/home-page/second-top.jpg);
    background-position: center;
    background-size: cover;
}

.after-banner-section .bottom-section .right-section .right-section-box {
    margin: 5vw;
}

.after-banner-section .bottom-section .right-section .right-section-box .title {
    font-size: 2.8vw;
    font-weight: 700;
    align-self: center;
    color: white;
}

.after-banner-section .bottom-section .right-section .right-section-box .sub-title {
    font-size: 1.5vw;
    font-weight: 400;
    font-style: normal;
    color: white;
    margin-bottom: 1.5vw;
}

.after-banner-section .bottom-section .right-section .right-section-box .paragraph {
    font-size: 1.4vw;
    font-weight: 400;
    font-style: normal;
    color: white;
    line-height: 1.7;
    margin-bottom: 2vw;
}

.after-banner-section .bottom-section .right-section .right-section-box .button {
    font-size: 1.5vw;
    color: #ffffff;
    padding: 1.3vw 1.3vw 1.3vw 1.3vw;
    text-transform: none;
    background: #f7941e;
    width: 12vw;
    text-align: center;
    margin-top: 3vw;
    border-radius: 3px;
}

.after-banner-section .bottom-section .right-section .right-section-box .button:hover {
    color: #ffffff;
    background: black;
}


/* Mobile */
@media screen and (max-width: 600px){
    .after-banner-section {
        height: auto;
    }

    .after-banner-section .top-options {
        display: contents;
    }

    .after-banner-section .top-options .option {
        height: 36vw;
        width: 100%;
        border-top: 10px solid #f7941e;
    }
    
    .after-banner-section .top-options .option .image {
        width: 24vw;
        height: 24vw;
    }

    .after-banner-section .top-options .option .side-text .sub-title {
        font-size: 5.6vw;
        color: #b7b7b7;
        margin: 4vw;
    }

    .after-banner-section .top-options .option .side-text .title {
        font-size: 8vw;
        font-weight: bold;
        margin: 4vw;
    }

    .after-banner-section .top-options .option .side-text {
        margin: 4vw;
    }
    
    .after-banner-section .bottom-section {
        display: contents;
    }

    .after-banner-section .bottom-section .left-section {
        height: 140vw;
    }

    .after-banner-section .bottom-section .right-section {
        height: fit-content;
    }

    .after-banner-section .bottom-section .right-section .right-section-box {
        padding: 6vw;
    }

    .after-banner-section .bottom-section .right-section .right-section-box .title {
        font-size: 11vw;
    }

    .after-banner-section .bottom-section .right-section .right-section-box .sub-title {
        font-size: 6vw;
        margin-bottom: 6vw;
    }

    .after-banner-section .bottom-section .right-section .right-section-box .paragraph {
        font-size: 5.6vw;
        font-weight: 400;
        font-style: normal;
        color: white;
        line-height: 1.7;
        margin-bottom: 8vw;
    }

    .after-banner-section .bottom-section .right-section .right-section-box .button {
        font-size: 6vw;
        padding: 6vw 6vw 6vw 6vw;
        width: 50vw;
    }
}