.team {
    border: 0px solid red;
    height: fit-content;
    width: 96%;
    margin: 2vw;
    margin-top: 5vw;
    margin-bottom: 5vw;
}

.team .title {
    font-size: 5vw;
    font-weight: 800;
    letter-spacing: 1px;
    text-align: center;
}

.team .members {
    display: flex;
    margin-top: 5vw;
    margin-bottom: 5vw;
    margin-bottom: 10vw
}

.team .members .member {
    border: 0px solid green;
    height: fit-content;
    width: 100%;
    text-align: center;
}

.team .members .member .pic-container {
    border: 0px solid red;
    height: fit-content;
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.team .members .member .pic-container .pic {
    border: 0px solid red;
    height: fit-content;
    width: 10vw;
    height: 10vw;
    border-radius: 100%;
    background-size: cover;
    background-position: center;
    transition: ease-in-out .5s;
}

.team .members .member .pic-container .pic:hover {
    filter: brightness(50%);
    transform: scale(1.2);
}

.team .members .member .full-name {
    font-size: 1.5vw;
    font-weight: bold;
    letter-spacing: 1px;
}

.team .members .member .full-name:hover {
    color: #f7941e ;
}

@media screen and (max-width: 600px){
    .team .members {
        flex-direction: column;
    }
    .team .members .member {
        margin-top: 10vw;
    }

    .team .members .member .pic-container .pic {
        width: 30vw;
        height: 30vw;
    }

    .team .members .member .full-name {
        font-size: 3.5vw;
    }
}