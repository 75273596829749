body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Toastify__toast-body {	
  text-align: center;
}	

.Toastify__toast--success {	
  background: #f8f9fa;	
  color: #486B00	
}	

.Toastify__close-button--success {	
  color: #486B00;	
}	

.Toastify__progress-bar--success {	
  background-color: #92D400;	
}	

.Toastify__toast--error {	
  background: #f8f9fa;	
  color: #470000	
}	

.Toastify__close-button--error {	
  color: #470000;	
}	

.Toastify__progress-bar--error {	
  background-color: #C9190B;	
}	

.Toastify__toast--info {	
  background: #f8f9fa;	
  color: gray	
}	

.Toastify__close-button--info {	
  color: black;	
}	

.Toastify__progress-bar--info {	
  background-color: orange;	
}	

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
