.home {
    background-color: white;
    height: 1000px;
}

html {
    scroll-behavior: smooth;
  }
  
.move-navbar-left {
    right: 350px;
    position: relative;
    animation: slideLeft 0.4s;
    transition: all .5s ease-in-out;
    filter: brightness(70%);
    background: white
}

@keyframes slideLeft {
    from { right: 0; }
    to   { right:  350px; }
}

.move-navbar-back {
    right: 0;
    position: relative;
    animation: slideRight 0.4s;
}

@keyframes slideRight {
    from { right: 350px; }
    to   { right:  0; }
}