.socials-section {
    border: 0px solid red;
    height: fit-content;
    width: 100%;
    display: flex;
    background-color: #f7941e;
    justify-content: center;
}

.socials-section .facebook-logo {
    width: 2vw;
    height: 4vw;
    margin: 5vw;
}

.socials-section .instagram-logo {
    width: 4vw;
    height: 4vw;
    margin: 5vw;
}

.socials-section .whatsapp-logo {
    width: 4vw;
    height: 4vw;
    margin: 5vw;
}

@media screen and (max-width: 600px){
    .socials-section {
        display: flex;
        flex-direction: column;
        padding-top: 8vw;
        padding-bottom: 10vw;
    }

    .socials-section .facebook-logo {
        width: 8vw;
        height: 16vw;
        margin: 5vw;
        align-self: center;
    }

    .socials-section .instagram-logo {
        width: 16vw;
        height: 16vw;
        margin: 5vw;
        align-self: center;
    }

    .socials-section .whatsapp-logo{
        width: 16vw;
        height: 16vw;
        margin: 5vw;
        align-self: center;
    }
}