.address {
  display: flex;
  justify-content: space-between;
  margin-left: 10vw;
  margin-right: 10vw;
}


.address .description {
  border: 0px solid green;
  font-size: 1vw;
  font-weight: bold;
  margin-bottom: 1vw;
  height: fit-content;
}

@media screen and (max-width: 600px){
  .address .description {
    font-size: 2vw;
  }

  .address .details .line {
    font-size: 2vw;
  }

  .application-modal .application-modal-content .key-value-pair .link {
    font-size: 2vw;
  }

  .application-modal .application-modal-content .key-value-pair .value {
    font-size: 2vw;
  }

  .application-modal .application-modal-content .paragraph {
    margin-top: 3vw;
    margin-bottom: 3vw;
    font-size: 2vw;
  }

  .application-modal .application-modal-content .key-value-pair .value-bold {
      font-size: 2vw;
  }

  .signature .description {
    font-size: 2vw;
  }

  .signature .details {
    font-size: 2vw;
  }
}