.news-and-updates {
    border: 0px solid red;
    height: fit-content;
    width: 99%;
    justify-content: center;
    display: flex;
}

.news-and-updates .slide-show-section {
    border: 0px solid green;
    height: fit-content;
    width: 60%;
    margin: 5vw;
    margin-right: 1vw;
}

.news-and-updates .slide-show-section .title-section {
    display: flex;
    justify-content: space-around;
}

.news-and-updates .slide-show-section .title-section .title {
    font-size: 40px;
    font-weight: 700;
    width: 100%;
}

.news-and-updates .slide-show-section .title-section .title-arrow {
    border-top: 3px solid;
    width: 100%;
    margin-top: 2.3vw;
    margin-right: 2vw;
}

.news-and-updates .slide-show-section .sub-title {
    font-size: 22px;
    color: #707375;
    margin-top: 8px;
    display: inline-block;
}

.news-and-updates .slide-show-section .slide-show-container {
    border: 0px solid black;
    height: fit-content;
    margin-top: 3.9vw;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.each-slide > div {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    height: 40vw;
}

.news-and-updates .quick-links {
    border: 0px solid black;
    height: 35vw;
    width: 60%;
    margin-top: 5vw;
}

.news-and-updates .quick-links .title-section {
    justify-content: space-around;
}

.news-and-updates .quick-links .title-section .title {
    font-size: 40px;
    font-weight: 700;
    width: 100%;
}

.news-and-updates .quick-links .title-section .sub-title {
    font-size: 22px;
    color: #707375;
    margin-top: 8px;
    display: inline-block;
    margin: 1vw;
}

.news-and-updates .quick-links .article {
    border: 0px solid black;
    height: fit-content;
    display: flex;
    margin-top: 0.5vw;
    margin-bottom: 5vw;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.news-and-updates .quick-links .logo-container {
    height: 10vw;
    width: 10vw;
    overflow: hidden;
}

.news-and-updates .quick-links .logo {
    height: 10vw;
    width: 10vw;
    background-image: url(../../../assets/images/home-page/icons/ukzn.png);
    background-position: center;
    background-size: cover;
    overflow: hidden;
    transition: ease-in-out .5s;
}


.news-and-updates .quick-links .article .logo:hover {
    filter: brightness(50%);
    transform: scale(1.2);
}

.news-and-updates .quick-links .article .logo::after {
    transition: ease-out .5s;
}

.news-and-updates .quick-links .date-and-title {
    border: 0px solid red;
    width: 70%
}

.news-and-updates .quick-links .date {
    border: 0px solid red;
    margin: 1vw;
    font-size: 1vw;
    font-weight: 600;
    color: #959595;
}

.news-and-updates .quick-links .title {
    border: 0px solid red;
    margin: 1vw;
    font-size: 1vw;
    font-weight: 700;
}

.news-and-updates .quick-links .date-and-title .title:hover {
    color: #f7941e;
}

@media screen and (max-width: 600px){
    .news-and-updates {
        display: inherit;
    }

    .news-and-updates .slide-show-section {
        width: 100%;
        margin: 0vw;
        text-align: center;
    }

    .news-and-updates .slide-show-section .title-section .title-arrow {
        display: none;
    }

    .each-slide > div {
        height: 66vw;
    }

    .news-and-updates .quick-links {
        width: 100%;
        text-align: center;
        display: inline-block;
    }

    .news-and-updates .quick-links .logo-container {
        height: 30vw;
        width: 30vw;
    }

    .news-and-updates .quick-links .logo {
        height: 30vw;
        width: 30vw;
    }

    .news-and-updates .quick-links .date {
        font-size: 5vw;
    }

    .news-and-updates .quick-links .title {
        font-size: 5vw;
    }
}