
.second-navbar {
    margin-top: 50px;
    background-color: white;
    border: 0px solid green;
    height: 110px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}

.fixed-top {
    margin-top: 0px;
    position: fixed;
}

.second-navbar .left-section {
    background-size: contain;
    height: 5vw;
    width: 20vw;
    border: 0px solid black;
    margin-left: 40px;
    background-repeat: no-repeat;
    background-image: url('../../../assets/images/focus-mentorship.png');
}

.second-navbar .right-section {
    border: 0px solid green;
    display: flex;
    margin-right: 20vh;
}

.second-navbar .right-section .item {
    margin-inline: 15px;
    display: flex;
    align-items: center;
}

.second-navbar .right-section .active {
    color: #f7941e;
}

.second-navbar .right-section :hover {
    color: #f7941e;
}

.bars-close-icons {
    display: none;
}


.hide {
    display: none;
}


@media screen and (max-width: 1260px){
    .bars-close-icons {
        border: 0px solid green;
        display: flex;
        margin-right: 50px;
    }
    .second-navbar {
        margin-top: 0px;
        position: relative;
    }
    .second-navbar .right-section {
        display: none;
    }
}

/* Mobile */
@media screen and (max-width: 600px){
    .second-navbar .left-section {
        background-size: contain;
        height: 10vw;
        width: 50vw;
    }
}