nav {
    position: absolute;
    top: 0;
    width: 100%;
    height: 50px;
    background: black;
}

.top-navbar {
    display: flex;
    justify-content: space-between;
    border: 0px solid red;
    color: #e6e6e6;
}

.top-navbar .left-section {
    display: flex;
    margin-left: 50px;
    border: 0px solid green;
    flex-direction: row;
    justify-content: space-between;
    font-size: 15px;
    align-items: center;
}

.top-navbar .left-section .phone-number {
    margin-right: 15px;
}

.top-navbar .left-section .email {
    margin-left: 15px;
}

.top-navbar .right-section {
    margin-right: 20px;
    border: 0px solid yellow;
    display: flex;
}

.top-navbar .right-section .item {
    margin-inline: 15px;
    display: flex;
    align-items: center;
}

.top-navbar .right-section .item:hover {
    color: #f7941e;
}

.top-navbar .right-section .last-item:hover {
    color: white;
}

.top-navbar .right-section .last-item {
    background-color: #f7941e;
    font-weight: bold;
    padding-inline: 20px;
    color: white;
}

@media screen and (max-width: 1260px){
    .top-navbar  {
        display: none;
    }
}